import './css/App.css';

// Delete After
import './css/temporaryLandingPage.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='conDiv'>
          <a className='conMainTitle'><span className="conMainTitleAlt">Beauty</span> takes time.</a>
          <br />
          <a className='conSubTitle'>This page is <span className="conMainTitleAlt">under development.</span></a>
          <p className='conMinor'>Consulate of the Republic of Nauru in Kuala Lumpur</p>
        </div>
      </header>
    </div>
  );
}

export default App;
